import React from 'react'
import PropTypes from 'prop-types'
import Content from './Content'
import {Link} from 'gatsby'

const TaxPageTemplate = ({ title, notice, content, contentComponent, rateSchedule }) => {
  const PageContent = contentComponent || Content

  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <p>{notice.message}</p>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-7 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <PageContent className="content" content={content} />
              <table className="table is-striped">
              <thead>
                <tr>
                  <td>Year</td><td>Debt Service</td><td>Maintenance</td><td>Total Tax Rate</td><td>Order Levying Taxes</td>
                </tr>
              </thead>
              <tbody>
              {rateSchedule.map( entry => (
                <tr>
                  <td>{entry.year}</td>
                  <td>{entry.ds == 0 ? "----" : "$"+String.fromCharCode(160)+entry.ds.toFixed(3)}</td>
                  <td>{entry.mo == 0 ? "----" : "$"+String.fromCharCode(160)+entry.mo.toFixed(3)}</td>
                  <td>$&nbsp;{(entry.mo + entry.ds).toFixed(3)}</td>
                                    { entry.order === null ? (<td></td>) : (<td><a className="button is-primary is-small" target="_blank" rel="noreferrer noopener" href={entry.order.publicURL}>Order Levying Taxes – {entry.year}</a></td>)}
                </tr>
              ))}
              </tbody>
              </table>
              <Notice notice={notice} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

TaxPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default TaxPageTemplate
